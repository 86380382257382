<div class="select-project-menu-block col-12">
  <span [hidden]="toggle" class="project-title">{{activeProject?.project.alias || activeProject?.project.name}}</span>
  <button
          class="trigger-menu-btn"
          (click)="menuToggle()"
          [ngClass]="toggle ? 'menu-opened' : 'menu-closed'">
    <span [hidden]="!toggle" class="title-for-select-projects-menu">Select project:</span>
    <mat-icon svgIcon="{{toggle ? 'arrow-up-icon' : 'arrow-down-icon'}}"></mat-icon>
  </button>
  
  <div class="select-projects-menu" [hidden]="!toggle">
    <button mat-menu-item *ngFor="let project of projects" (click)="setActiveProject(project.id)">
      <span>{{project.name}}</span>
    </button>
  </div>
  <!--<mat-menu #menu="matMenu" class="remove-padding">-->
    <!--<button mat-menu-item *ngFor="let project of projects" (click)="setActiveProject(project.id)">-->
      <!--<span>{{project.name}}</span>-->
    <!--</button>-->
  <!--</mat-menu>-->
</div>
