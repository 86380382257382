import {Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren} from '@angular/core';

@Component({
  selector: 'app-top-filter',
  templateUrl: './top-filter.component.html',
  styleUrls: ['./top-filter.component.scss']
})
export class TopFilterComponent implements OnInit, OnChanges {

  @Input() topFilterList: any;
  @Input() topFilterTitle: string = '';
  @ViewChildren('filters') filters: QueryList<any>;
  @Output() setFilterSensorTypeValue = new EventEmitter();
  @Output() setFilterSensorType = new EventEmitter();
  onlyIconsFilter = false;
  activeLink;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any) {
    this.setActiveSensor(changes.topFilterList.currentValue[0]);
    this.activeLink = changes.topFilterList.currentValue[0];
  }

  setActiveSensor(sensor): void {
    if (!sensor) return;
    this.topFilterList.map(f => f.selected = false);
    sensor.selected = !sensor.selected;

    this.setFilterSensorType.emit(sensor);
    this.setFilterSensorTypeValue.emit(sensor.type);
  }
}
