<div class="data-range-calendar d-flex">
  <mat-icon (click)="picker.open()" svgIcon="calendar" class="calendar-icon"></mat-icon>

  <mat-form-field appearance="fill">
    <mat-label>Duration</mat-label>

    <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker" [max]="maxDate">
      <input matStartDate
             readonly
             formControlName="start"
             placeholder="Start date"
             (focus)="picker.open()"/>
      <input matEndDate
             readonly
             formControlName="end"
             placeholder="End date"
             (focus)="picker.open()"/>
    </mat-date-range-input>
    <mat-date-range-picker #picker startView="year" [startAt]="dateRange.controls.end"></mat-date-range-picker>

    <mat-error *ngIf="dateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
  </mat-form-field>
</div>
