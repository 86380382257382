import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { DeviceConnection, DeviceReachabilityService } from 'src/app/core/services/fw/device-reachability.service';

@Component({
  selector: 'app-device-settings-dialog',
  templateUrl: './device-settings-dialog.component.html',
  styleUrls: ['./device-settings-dialog.component.scss']
})
export class DeviceSettingsDialogComponent implements OnInit {
  public projectSSID: string;
  public projectPassword: string;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<DeviceSettingsDialogComponent>
  ) {
    this.projectSSID = data.ssid;
    this.projectPassword = data.password;
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  continue() {
    window.open("http://192.168.4.1/index.html", "_blank");
  }
}

