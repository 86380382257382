<div class="navigation">
  <div class="navigation-logo-block"></div>
  <hr class="mt-0 mb-0">
  <mat-nav-list class="navigation-content">
    <div class="navigation-item" mat-list-item [routerLink]="['/dashboard']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="home-icon"></mat-icon>
      <span mat-line>{{'dashboard' | translate}}</span>
    </div>
    <!-- <div class="navigation-item" mat-list-item [routerLink]="['/templates']" [routerLinkActive]="'activeNavItem'">
       <mat-icon svgIcon="templates-icon"></mat-icon>
       <span mat-line>{{'templates' | translate}}</span>
     </div>-->
    <div class="navigation-item" mat-list-item [routerLink]="['/history']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="history-icon"></mat-icon>
      <span mat-line>{{'history' | translate}}</span>
    </div>
    <div class="navigation-item" mat-list-item [routerLink]="['/users']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="users-icon"></mat-icon>
      <span mat-line>{{'users' | translate}}</span>
    </div>
    <!-- <div class="navigation-item" mat-list-item [routerLink]="['/projects']" [routerLinkActive]="'activeNavItem'">
      <mat-icon svgIcon="projects-icon"></mat-icon>
      <span mat-line>{{'projects' | translate}}</span>
    </div> -->
  </mat-nav-list>
  
  <div class="mt-40 mb-0" *ngIf="(project$ | async)?.extraSettings?.isExistFwConfigFile" >
    <hr class="mt-0 mb-0">
    <div class="navigation-item" mat-list-item *ngIf="project$ | async as project" (click)="openDeviceSettingsDialog(project)" >
      <mat-icon svgIcon="settings"></mat-icon>
      <span mat-line>{{'project_settings' | translate}}</span>
    </div>
  </div>
</div>
