import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from '@angular/material/legacy-dialog';
import { DeviceSettingsDialogComponent } from 'src/app/shared/components/device-settings-dialog/device-settings-dialog.component';
import { ProjectsService } from 'src/app/core/services/projects/projects.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-navigation',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  project$ = this.projectsService.activeProject$.asObservable().pipe(map(el => el?.project));

  currentTime;
  currentPeriod;
  nightMode = false;
  currentUserEmail = '';
  currentUserName = '';

  @Output() openNavigation: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private projectsService: ProjectsService,
  ) {
  }

  ngOnInit() {
  }

  openDeviceSettingsDialog(project) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = project.systemWifi || {ssid: '', password: ''};

    return this.dialog.open(DeviceSettingsDialogComponent, dialogConfig);
  }
}
