<div class="edit-project-dialog">
  <div class="edit-project-dialog-card" *ngIf="!isSubmitted">
    <div class="pop-up-card-header center edit-project-dialog-header">
      <span class="edit-project-dialog-title">Edit project {{editProjectAlias ? 'alias' : 'name'}}</span>
    </div>
    <div class="edit-project-dialog-content">
      <form [formGroup]="editProjectNameForm" *ngIf="!editProjectAlias">
        <div class="field-row">
          <label class="field-type">
            Project name
          </label>
          <input type="text" placeholder="Project name" formControlName="name">
          <div *ngIf="editProjectNameForm.get('name').hasError('required')" class="help-block"
               [ngClass]="{'error': editProjectNameForm.get('name').hasError('required')}">
            <span>Project name is required</span>
          </div>
        </div>
      </form>
      <form [formGroup]="editProjectAliasForm" *ngIf="editProjectAlias">
        <div class="field-row">
          <label class="field-type">
            Project alias
          </label>
          <input type="text" placeholder="Project alias" formControlName="alias">
        </div>
      </form>

      <label class="checkbox-container" for="alias" *ngIf="isChangeProjectNameEnabled">
        <input (change)="changeAlias(alias.checked)" #alias id="alias" type="checkbox">
        <span>{{'change_project_alias' | translate}}</span>
      </label>

    </div>

    <mat-dialog-actions align="center">
      <button mat-stroked-button color="primary" class="edit-project-dialog-btns" (click)="close()">
        {{'cancel' | translate}}
      </button>
      <button mat-flat-button color="primary" class="edit-project-dialog-btns" [disabled]="editProjectNameForm.invalid"
              (click)="save()">
        {{'save' | translate}}
      </button>
    </mat-dialog-actions>
  </div>

  <div class="edit-project-dialog-card" *ngIf="isSubmitted">
    <div class="pop-up-card-header center edit-project-dialog-header">
      <span class="edit-project-dialog-title">{{'please_wait' | translate}}</span>
    </div>
    <div class="edit-project-dialog-content edit-project-dialog-loader loader-inline">
      <img src="../../../../assets/spinner.gif" class="rotate-spinner" alt="spinner">
    </div>
  </div>
</div>
