import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../../../core/services/projects/projects.service';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-active-project-status',
  templateUrl: './active-project-status.component.html',
  styleUrls: ['./active-project-status.component.scss']
})
export class ActiveProjectStatusComponent implements OnInit, OnDestroy {
  activeProject;
  activeProjectStatus;
  activeProjectDeviceId;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private projectsService: ProjectsService,
              private cdRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.getActiveProjectStatus();
  }

  getActiveProjectStatus(): void {
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(project => {
      if (project) {
        this.activeProjectStatus = '';
        this.activeProjectDeviceId = null;
        this.activeProject = project;
        if (this.activeProject.project.mDash && this.activeProject.project.mDash.deviceId) {
          this.activeProjectDeviceId = this.activeProject.project.mDash.deviceId;
          this.getProjectStatus(this.activeProject.project.mDash.deviceId);
        } else {
          this.activeProjectStatus = {online: false};
        }
      }
    })
  }

  getProjectStatus(deviceId) {
    if (!deviceId) return;
    this.projectsService.getProjectStatus(deviceId).subscribe(res => {
      this.activeProjectStatus = res ?? {online: false};
      this.cdRef.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
