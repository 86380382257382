import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-drop-down-filter',
  templateUrl: './drop-down-filter.component.html',
  styleUrls: ['./drop-down-filter.component.scss']
})
export class DropDownFilterComponent implements OnInit, OnChanges {
  @Input() isMultiple: boolean = false;
  @Input() isAllCheckboxVisible: boolean = false;
  @Input() maxSelectedFiltersCount: number;
  @Input() bindValueKey: string = 'title';
  @Input() translateBindValue: boolean = true;
  @Input() translateBindValuePrefix: string = '';
  @Input() filterLabel: string;
  @Input() filterList: any[] = [];
  @Output() filterChanged: EventEmitter<any> = new EventEmitter<any>();
  isAllSelected: boolean = false;
  filterControl: UntypedFormControl = new UntypedFormControl({});
  multipleFiltersControl: UntypedFormControl = new UntypedFormControl([]);
  multipleFiltersArray: any[] = [];

  constructor() {
  }

  ngOnChanges(changes: any) {
    if (!changes.filterList) return;
    const filters = changes.filterList.currentValue.filter(el => el.selected);
    if (this.isMultiple) {
      this.multipleFiltersControl.setValue(filters.length > 1 ? [...filters] : [filters[0]])
      this.multipleFiltersArray = filters;
      this.filterChangeNotify(this.multipleFiltersControl.value);
    } else {
      this.filterControl.setValue(filters[0]);
      this.filterChangeNotify(this.filterControl.value);
    }
  }

  ngOnInit(): void {
  }

  onFilterChange(): void {
    this.filterList.map(f => f.selected = (this.filterControl.value.name.toLowerCase() === f.name.toLowerCase()));
    this.filterChangeNotify({...this.filterControl.value, selected: true});
  }

  toggleAllSelection(): void {
    this.isAllSelected = !this.isAllSelected;
    this.filterList.map(f => f.selected = this.isAllSelected);
    this.multipleFiltersArray = this.isAllSelected ? [...this.filterList] : [];
    this.multipleFiltersControl.setValue(this.multipleFiltersArray);
    this.filterChangeNotify(this.multipleFiltersControl.value);
  }

  compareFilters(filter1: any, filter2: any): boolean {
    return filter1 && filter2 && filter1.name.toLowerCase() === filter2.name.toLowerCase();
  }

  optionChanged(filter, $event): void {
    if (!$event.isUserInput) return;
    filter.selected = !filter.selected;
    if (!filter.selected) {
      this.multipleFiltersArray = this.multipleFiltersArray.filter(obj => obj !== filter);
    } else if (filter.selected) {
      this.multipleFiltersArray.push(filter);
      if (this.maxSelectedFiltersCount > 0 && this.multipleFiltersArray.length > this.maxSelectedFiltersCount) {
        for (let i = 0; i < this.multipleFiltersArray.length - this.maxSelectedFiltersCount; i++) {
          this.multipleFiltersArray[i].selected = false;
        }
        this.multipleFiltersArray = this.multipleFiltersArray.slice(-this.maxSelectedFiltersCount);
      }
    }
   this.setMultipleFilters();
  }

  private setMultipleFilters(): void {
    this.multipleFiltersControl.patchValue([...this.multipleFiltersArray]);
    this.isAllSelected = this.multipleFiltersArray.length === this.filterList.length;
    this.filterList.map(f => f.selected = (this.multipleFiltersArray.some(v => f.name.toLowerCase() === v.name.toLowerCase())));
    this.filterChangeNotify(this.multipleFiltersArray);
  }

  private filterChangeNotify(value): void {
    this.filterChanged.emit(value);
  }
}
