import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Room} from "../../../models/room.model";
import {MeasurementEnum} from "../../../models/measurement.model";
import {HistoryService} from "../../../core/services/history/history.service";
import {AlertService} from "../../../core/services";
import {TranslateService} from "@ngx-translate/core";
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-edit-project-adjustments',
  templateUrl: './edit-project-adjustments.component.html',
  styleUrls: ['./edit-project-adjustments.component.scss']
})
export class EditProjectAdjustmentsComponent implements OnInit {

  projectsRooms: Room[];
  projectUuid:string;
  measurements;
  MeasurementEnum = MeasurementEnum;

  constructor(@Inject(MAT_DIALOG_DATA) data,
              public dialogRef: MatDialogRef<EditProjectAdjustmentsComponent>,
              private historyService: HistoryService,
              private alertService: AlertService,
              private translate: TranslateService
  ) {
   this.projectsRooms = data.rooms;
    this.measurements = data.measurements;
    this.projectUuid = data.projectUuid;
  }

  ngOnInit(): void {
    this.getRoomMeasurements();
  }

  getRoomMeasurements(): void {
    this.projectsRooms = this.projectsRooms.map(room => {
      const measurements = this.measurements?.measurement?.find(measurement => measurement.roomUuid === room.uuid)?.measurement ||
          { heatingOffset: 0 };
      return {
        ...room,
        measurements
      }
    })
  }

  checkNumber(e, measurement, key) {
    measurement[key] = e;
    setTimeout(() => measurement[key] = e == null ? null : Math.max(-3000, Math.min(3000, e)), 50);
  }

  saveMeasurements() {

    const data = {
      projectUuid: this.projectUuid,
      uuid: this.measurements.uuid ?? uuidv4(),
      measurement: []
    };
    this.projectsRooms.forEach(room => {
      // Set default value if needed
      for (var name in room.measurements) {
        room.measurements[name] = room.measurements[name] == null ? 0 : room.measurements[name]
      }

      data.measurement.push({
        roomUuid: room.uuid,
        measurement: {
          ...room.measurements
        }
      });
    })


    this.historyService.postProjectAdjustments([data]).subscribe(res => {
      if(res) {
        this.dialogRef.close();

        this.alertService.openInfoModal(
            this.translate.instant('project_adjustments'),
            this.translate.instant('update_project_adjustments')
        );
      }
    }, err => {
      this.alertService.openErrorModal('Error', err.error.error.error)
    })

  }
  trackByFn(index) {
    return index;
  }


}
