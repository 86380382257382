import * as moment from 'moment-timezone';
import {ProjectScheduler, dayEnum} from '../../../models/scheduler.model';
import {Project, eqaulSensorsInRoom} from '../../../models/project.model';
import {Injectable} from '@angular/core';
import { schedulerValueToUnit, Sensor } from 'src/app/models/sensor';
import { findLastIndex } from 'src/app/utils/foundation';

@Injectable({
  providedIn: 'root'
})

export class SchedulerUtils {

  constructor() {}

  /// Sort values by time. Backend can return not sorted values
  sortTemplateValues(templates){
    templates.forEach(template => {
      template.values = template.values.sort((v1, v2) => (Number(v1[0]) < Number(v2[0]) ? -1 : 1));
    })
  }

  getActiveSchedulers(projectScheduler: ProjectScheduler, project: Project): { [room: string]: { [sensorType: string]: number } } {
    const daysOfTheWeek = moment()
        .tz(project.timeZoneId ? project.timeZoneId : moment.tz.guess())
        .isoWeekday() - 1;

    // timestamp of start of the day in timezone
    const unixTimestampStartOfTheDay = moment()
      .tz(project.timeZoneId ? project.timeZoneId : moment.tz.guess())
      .startOf('day')
      .unix();

    // timestamp of current time in timezone
    const unixTimestampOfCurrentTime = moment()
      .tz(project.timeZoneId ? project.timeZoneId : moment.tz.guess())
      .unix();

    const timeFromStartOfTheDay = unixTimestampOfCurrentTime - unixTimestampStartOfTheDay;


    const result: { [room: string]: { [sensorType: string]: number } } = {};

    projectScheduler.rooms.forEach(room => {
      // Init map
      result[room.roomUuid] = {};

      // Insert scheduler values
      room.schedulers.forEach(scheduler => {
        const dayTemplate = scheduler.templates.find(template => {
          return dayEnum[template.day] === daysOfTheWeek;
        });

        // Find active setPoint that <= currentTime
        let index = -1;
        if (dayTemplate?.template?.values) {
          index = findLastIndex(dayTemplate.template.values, ((item) => Number(item[0]) <= timeFromStartOfTheDay));
        }

        if (index >= 0) {
          // Add from current day
          const value = Number(dayTemplate.template?.values[index][1]);
          result[room.roomUuid][dayTemplate.template.sensorType] = this.normalizeSchedulerValue(
            value, dayTemplate.template.sensorType as Sensor, room.roomId, project
          );
        } else {
          // Add from previous day
          const prevDay = daysOfTheWeek - 1 < 0 ? 6 : daysOfTheWeek - 1;
          const prevDayTemplate = scheduler.templates.find(template => {
            return dayEnum[template.day] === prevDay;
          });
          
          const length = (prevDayTemplate) && (prevDayTemplate.template?.values.length);
          if (length && length > 0) {
            const value = Number(prevDayTemplate.template?.values[length - 1][1]);
            result[room.roomUuid][prevDayTemplate.template.sensorType] = this.normalizeSchedulerValue(
              value, prevDayTemplate.template.sensorType as Sensor, room.roomId, project
            );
          } else {
            // Corrupted template, no value should be displayed
          }
        }
      });
    });
    return result;
  }

  /// Converts value to sensor Unit space
  normalizeSchedulerValue(value: number, type: Sensor, roomId: number, project: Project): number {
    const sensorsCount = eqaulSensorsInRoom(type, roomId, project);
    return schedulerValueToUnit(type, sensorsCount)(value);
  }

}
