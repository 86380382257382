import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ProjectScheduler,
  SchedulerActivePoint,
  SchedulerRulePoint,
  SchedulerRulePointParams
} from '../../../models/scheduler.model';
import {SchedulerUtils} from './schedulerUtils';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

  constructor(private apiService: ApiService,
              private schedulerUtils: SchedulerUtils) {
  }

  get activeProject() {
    return localStorage.getItem('activeProject') ? JSON.parse(localStorage.getItem('activeProject')) : '';
  }

  getScheduler(projectId, roomsIds?: string[]): Observable<ProjectScheduler> {
    return this.apiService.get(`/scheduler/publishProject/${projectId}/?rooms=${roomsIds.join()}`)
      .pipe(
        map(res => {
          // Sort template values 
          if (res['data'].template){
            this.schedulerUtils.sortTemplateValues(res['data'].template);
          }

          // Set template
          res['data'].rooms.forEach(room => {
            room.schedulers.forEach(scheduler => {
              scheduler.templates.forEach(template => {

                template.template = res['data'].template.find(obj => obj.id === template.templateId)
              });
              room.setValues = (this.activeProject) &&
                this.schedulerUtils.getActiveSchedulers(res.data, this.activeProject)[room.roomUuid]
            })
          });
          return res.data;
        })
      );
  }

  getSchedulerActivePoints(projectId: number): Observable<SchedulerActivePoint[]> {
    return this.apiService.get(`/scheduler/publishProject/${projectId}/activePoints`).pipe(map(res => res.data))
  }

  setSchedulerRulePoint(body: SchedulerRulePointParams): Observable<SchedulerRulePoint> {
    return this.apiService.post(`/schedulerRule/setPoint`, { data: body })
  }
}
