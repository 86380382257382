import {Component, OnInit, Inject, EventEmitter} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AlertType} from '../alert/alert.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-project-wifi-dialog',
  templateUrl: './edit-project-wifi-dialog.component.html',
  styleUrls: ['./edit-project-wifi-dialog.component.scss']
})
export class EditProjectWifiDialogComponent implements OnInit {
  editProjectWifiForm: UntypedFormGroup;
  projectSSID: string;
  projectPassword: string;
  isSubmitted;
  onSave = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EditProjectWifiDialogComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.projectSSID = data.ssid;
    this.projectPassword = data.password;
    this.isSubmitted = data.isSubmitted;
  }

  ngOnInit() {
    this.editProjectWifiForm = this.fb.group({
      ssid: new UntypedFormControl(this.projectSSID, [Validators.required]),
      password: new UntypedFormControl(this.projectPassword, [Validators.required])
    });
  }

  save() {
    this.isSubmitted = true;
    this.onSave.emit(this.editProjectWifiForm.value);
  }

  close() {
    this.dialogRef.close();
  }
}

