<div class="drop-down-filter">
  <mat-form-field appearance="fill" *ngIf="!isMultiple">
    <mat-label>{{ (filterLabel.toLowerCase()) | translate }}
      <mat-icon svgIcon="arrow-down-icon"></mat-icon>
    </mat-label>
    <mat-select [formControl]="filterControl" panelClass="primary-select-panel"
                [compareWith]="compareFilters"
                (selectionChange)="onFilterChange()">
      <mat-option *ngFor="let filter of filterList" [value]="filter">
        {{ translateBindValue ? ((translateBindValuePrefix + filter[bindValueKey].toLowerCase()) | translate) : filter[bindValueKey] }}
        <mat-icon [svgIcon]="filter.icon" class="icon" *ngIf="filter.icon"></mat-icon>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="align-right" appearance="fill" *ngIf="isMultiple">
    <mat-label>{{ (filterLabel.toLowerCase()) | translate }}
      <mat-icon svgIcon="arrow-down-icon"></mat-icon>
    </mat-label>
    <mat-select [formControl]="multipleFiltersControl" panelClass="primary-select-panel"
                [compareWith]="compareFilters" multiple>
      <mat-select-trigger>
        {{ multipleFiltersControl.value?.length ? (translateBindValue ? (translateBindValuePrefix + multipleFiltersControl.value[0][bindValueKey]?.toLowerCase() | translate) : multipleFiltersControl.value[0][bindValueKey]) : '' }}
        <span *ngIf="multipleFiltersControl.value && multipleFiltersControl.value.length > 1">
          (+{{multipleFiltersControl.value.length - 1}} {{multipleFiltersControl.value?.length === 2 ? 'other' : 'others'}})
        </span>
      </mat-select-trigger>
      <div class="select-all" *ngIf="isAllCheckboxVisible">
        <mat-checkbox [checked]="isAllSelected"
                      (change)="toggleAllSelection()">{{'all' | translate}}</mat-checkbox>
      </div>
      <mat-option *ngFor="let filter of filterList" [value]="filter"
                  (onSelectionChange)="optionChanged(filter, $event)">
        {{ translateBindValue ? ((translateBindValuePrefix + filter[bindValueKey].toLowerCase()) | translate) : filter[bindValueKey] }}
        <span *ngIf="filter.chartColor" class="rounded-circle filter-chart-color"
              [ngStyle]="{'background-color': filter.chartColor}"></span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
