<div class="confirm">
  <div class="confirm-card">
    <div class="pop-up-card-header center confirm-header">
      <span class="confirm-title">{{'RAW_DATA' | translate}}</span>
    </div>
    <div class="confirm-msg">
      <span class="msg">
          {{'The_file_will_be_generated_and_sent_to_you_by_e-mail. The_process_can_take_a_while.' | translate}}</span>
    </div>

    <form class="email-form">
      <div class="field-row">
        <label class="field-type">
          E-mail
        </label>
        <mat-form-field class="example-full-width" appearance="fill">
          <input type="email"
                 matInput [formControl]="emailFormControl">
        </mat-form-field>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </div>
      <mat-dialog-actions align="center">
        <button mat-stroked-button color="primary" class="confirm-btns" [mat-dialog-close]="false">
          {{"cancel"| translate}}
        </button>
        <button mat-flat-button color="primary" class="confirm-btns" type="submit" (click)="sentEmail()"
                [mat-dialog-close]="true">
          {{"Generate"| translate}}
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
