import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {MatDividerModule} from '@angular/material/divider';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatTreeModule} from '@angular/material/tree';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {ChartsModule} from 'ng2-charts';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {ClipboardModule} from '@angular/cdk/clipboard';

import * as fromComponents from './components';
import {
  AlertComponent,
  ConfirmComponent,
  CreateDemoProjectDialogComponent,
  EditProjectNameDialogComponent,
  EditProjectWifiDialogComponent,
  SchedulerActionsComponent
} from './components';

import {AlertService} from '../core/services';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FilterBySensor} from './pipes/sensor.pipe';
import {TranslateModule} from '@ngx-translate/core';


/**
 * SharedModule contains code that will be used across the app and Feature Modules. This includes:
 * - common stateless components ('dumb' components)
 * - commonly used pipes and directives
 * - export the commonly used Angular modules (eg CommonModule or the FormsModule) so they can be easily used across the app
 * - export UI Modules (eg Angular Material) or components that are used a lot in the application
 * Note: this might introduce unnecessary loading speed if you don't use those modules/components a lot;
 *       if that's the case, you might want to import these Angular modules only in the Feature Modules that use them.
 *
 * Important note:
 * DO NOT import the SharedModule into the main AppModule or CoreModule, only in specific Feature Modules as needed
 */

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatSelectModule,
        MatMenuModule,
        MatDialogModule,
        MatDividerModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatCardModule,
        MatTabsModule,
        MatTreeModule,
        MatSidenavModule,
        MatListModule,
        MatRadioModule,
        MatSliderModule,
        NgbModule,
        TranslateModule,
        ChartsModule,
        MatExpansionModule,
        ClipboardModule
    ],
    declarations: [
        FilterBySensor,
        ...fromComponents.components,
    ],
    exports: [
        /* core modules */
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        MatSelectModule,
        MatInputModule,
        MatIconModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatDialogModule,
        MatDividerModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatCardModule,
        MatTabsModule,
        MatTreeModule,
        MatMenuModule,
        MatSidenavModule,
        MatListModule,
        MatRadioModule,
        MatSliderModule,
        MatDatepickerModule,
        MatNativeDateModule,
        TranslateModule,
        FilterBySensor,
        MatExpansionModule,
        ...fromComponents.components
    ],
    providers: [AlertService]
})
export class SharedModule {
}
