import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from "@angular/material/legacy-dialog";
import {ModalConfirmData} from "../confirm/confirm.component";

@Component({
  selector: 'app-scheduler-actions',
  templateUrl: './scheduler-actions.component.html',
  styleUrls: ['./scheduler-actions.component.scss']
})
export class SchedulerActionsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: any  ) { }

  ngOnInit(): void {
  }

  days = [
    {name: 'Monday', selected: true},
    {name: 'Tuesday', selected: true},
    {name: 'Wednesday', selected: true},
    {name: 'Thursday', selected: true},
    {name: 'Friday', selected: true},
    {name: 'Saturday', selected: true},
    {name: 'Sunday', selected: true},
  ];

  selectDay(ind: number): void {
    this.days[ind].selected = !this.days[ind].selected;
  }

}
