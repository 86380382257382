<div class="user-profile">
  <div class="user-profile__wrapper">
        <span class="user-profile__name hidden-sm-down"
              *ngIf="currentUser && currentUser.email">{{ currentUser.email }}</span>

    <div class="profile-menu"
         [matMenuTriggerFor]="profileMenu"
         #profileTrigger="matMenuTrigger"
         [ngClass]="{'profile-menu_active': profileTrigger.menuOpen}"
         [routerLinkActive]="'profile-menu_active'">
      <img src="/assets/images/profile-img.png" alt="" class="user-profile__image"/>

      <mat-menu #profileMenu="matMenu" yPosition="above">
        <ul class="profile-menu__list">
          <li class="profile-menu__item"
              mat-menu-item [matMenuTriggerFor]="languages">
            <div class="profile-menu__label">Language</div>
          </li>
          <li class="profile-menu__item" (click)="navigateToSupport()" mat-menu-item [disabled]="!currentUser?.id">
            <mat-icon svgIcon="support" class="profile-menu__icon"></mat-icon>
            <div class="profile-menu__label">{{'support' | translate}}</div>
          </li>
          <li class="profile-menu__item" (click)="onLogOut()" mat-menu-item>
            <mat-icon svgIcon="log-out" class="profile-menu__icon"></mat-icon>
            <div class="profile-menu__label">{{'log_out' | translate}}</div>
          </li>

          <mat-menu #languages="matMenu" class="mat-menu-language">
            <button (click)="changeLang('en')" mat-menu-item>
              <mat-icon [style.visibility]="getCurrentLang() === 'en' ? 'visible' : 'hidden'">check
              </mat-icon>
              EN
            </button>
            <button (click)="changeLang('de')" mat-menu-item>
              <mat-icon [style.visibility]="getCurrentLang() === 'de' ? 'visible' : 'hidden'">check
              </mat-icon>
              DE
            </button>
          </mat-menu>
        </ul>
      </mat-menu>
    </div>
  </div>
</div>
