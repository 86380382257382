import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {UsersService} from '../../../../../core/services/users/users.service';
import {AuthService} from '../../../../../core/services';
import {TranslateService} from '@ngx-translate/core';
import {takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../../../core/services/projects/projects.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  currentUser;
  activeProject;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private usersService: UsersService,
              private authService: AuthService,
              private translate: TranslateService,
              private cdRef: ChangeDetectorRef,
              private projectsService: ProjectsService) {
  }

  ngOnInit(): void {
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(activeProjectData => {
      if (activeProjectData) {
        this.activeProject = activeProjectData;
        this.getProjectUser();
      }
    })
  }

  getProjectUser() {
    this.usersService.getCurrentUser().subscribe(res => {
      this.currentUser = res;
      this.cdRef.detectChanges();
    })
  }

  onLogOut() {
    this.authService.logOut();
  }

  changeLang(lang) {
    this.translate.use(lang);
  }

  getCurrentLang() {
    return this.translate.currentLang;
  }

  navigateToSupport() {
    let url = `https://climeo.com/en/send-request-page/?user=${this.currentUser.id}`;
    if (this.activeProject?.project?.uuid) url += `&project=${this.activeProject?.project?.uuid}`
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
