import {Component, OnInit, Inject, Output, EventEmitter} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AlertType} from '../alert/alert.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-create-demo-project-dialog',
  templateUrl: './create-demo-project-dialog.component.html',
  styleUrls: ['./create-demo-project-dialog.component.scss']
})
export class CreateDemoProjectDialogComponent implements OnInit {
  templatesList;
  selectedDemoTemplate;
  isSubmitted;
  onSave = new EventEmitter();

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CreateDemoProjectDialogComponent>,
  ) {
    this.templatesList = data.templatesList;
    this.isSubmitted = data.isSubmitted;
  }

  ngOnInit() {
  }

  save() {
    this.isSubmitted = true;
    this.onSave.emit(this.selectedDemoTemplate);
  }

  close() {
    this.dialogRef.close();
  }
}

