import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from './core/guards/auth.guard';
import {LoginGuard} from './core/guards/login.guard';
import {AppComponent} from './app.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/sign-in',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard-v2/dashboard-v2.module').then(m => m.DashboardV2Module),
    canActivate: [AuthGuard]
  },
  {
    path: 'client/:shareLink',
    loadChildren: () => import('./modules/dashboard-v2/dashboard-v2.module').then(m => m.DashboardV2Module),
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notification/notification.module').then(m => m.NotificationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sign-in',
    loadChildren: () => import('./modules/authentication/sign-in/sign-in.module').then(m => m.SignInModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./modules/authentication/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
  },
  {
    path: 'reset-password/:resetPassToken',
    loadChildren: () => import('./modules/authentication/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./modules/authentication/sign-up/sign-up.module').then(m => m.SignUpModule)
  },
  /*{
    path: 'templates',
    loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'templates/:sensorType',
    loadChildren: () => import('./modules/templates/templates.module').then(m => m.TemplatesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'templates/custom/:sensorType',
    loadChildren: () => import('./modules/custom-template/custom-template.module').then(m => m.CustomTemplateModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'templates/custom/:sensorType/:templateId',
    loadChildren: () => import('./modules/custom-template/custom-template.module').then(m => m.CustomTemplateModule),
    canActivate: [AuthGuard]
  },*/
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    loadChildren: () => import('./modules/history/history.module').then(m => m.HistoryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'projects',
    loadChildren: () => import('./modules/projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'client/:shareLink',
    component: AppComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/sign-in',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
