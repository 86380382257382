import {Injectable} from '@angular/core';
import {ApiService} from '../../api/api';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {FULL_PERMISSIONS, PERMISSIONS, PERMISSIONS_KEYS} from '../../../models/permission.model';
import {USER_ROLES} from "../../../models/userRoles";

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  permissions = new BehaviorSubject(null);
  role = 'user';
  projectsPermissions = this.permissions as Observable<any>;


  constructor(private apiService: ApiService) {
  }

  getPermissionsForm() {
    return this.apiService.get('/projectPermission/form');
  }

  getPermissions() {
    return this.apiService.get('/projectPermission').pipe(tap(res => {
      return this.permissions.next(res.data);
    }));
  }

  getPermission(permission, projectId) {
    return this.projectsPermissions.pipe(map(projectPermissions => {
      if (projectPermissions) {
        return this.getProjectPermission(permission, projectId, projectPermissions);
      }
    }))
  }

  getAllPermission(projectId) {
    return this.projectsPermissions.pipe(map(projectPermissions => {
      if (!projectPermissions) { return {} }
      const project = projectPermissions.find(project => project.projectId === +projectId);
      this.role = project && project.role ? project.role : "admin";
      return project && project.permissions ? project.permissions : [];
    }))
  }

  getPermissionSync<T>(permission: string, projectId: string, defaultValue: T): T {
    if (!this.permissions?.value) { return defaultValue; }
    const value = this.getProjectPermission(permission, projectId, this.permissions.value);
    return value == undefined || value == null ? defaultValue : value;
  }

  private getProjectPermission(permission: string, projectId: string, projectPermissions) {
    const project = projectPermissions.find(p => p.projectId === +projectId);

    if (project && permission === PERMISSIONS.IS_ADMIN) {
      return project.role === USER_ROLES.ADMIN;
    }

    if (project && project.permissions) {
      const adminPermission = project.permissions.find(item => item.key === PERMISSIONS_KEYS.admin)?.value;
      const isAdmin = adminPermission == null || adminPermission == undefined ? true : adminPermission
      const perm = project.permissions.find(item => item.key === PERMISSIONS_KEYS[permission]);

      if (perm === undefined) {
        return this.defaultPermissions(permission, project);
      }
      
      if (permission === PERMISSIONS.SHARE_PROJECT && isAdmin) {
        return true
      }

      return (perm && perm.value);
    } else {
      return this.defaultPermissions(permission, project);
    }
  }

  private defaultPermissions(permission: string, project) {
    const fullPerm = FULL_PERMISSIONS.find(item => item.key === PERMISSIONS_KEYS[permission]);
    return fullPerm.value  || (project.role === USER_ROLES.ADMIN && permission === PERMISSIONS.SHARE_PROJECT);
  }
}
