import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {AlertService} from "../../../core/services";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectsService } from 'src/app/core/services/projects/projects.service';
import { ShareProjectService } from 'src/app/core/services/share-project/share-project.service';
import { TranslateService } from '@ngx-translate/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { Room } from 'src/app/models/room.model';
import { USER_ROLES } from 'src/app/models/userRoles';
import { PermissionsService } from 'src/app/core/services/permissions/permissions.service';
import { PERMISSIONS } from 'src/app/models/permission.model';

type InputSettings = {
  link: string;
  projectId: number;
  userId?: number;
  email: string;
  permissions?: {
    role: string,
    rooms?: string[]
  }
  onShared: () => void;
};

type RoomPermission = {
  room: {id: number, uuid: string},
  checked: boolean
}

@Component({
  selector: 'app-link-created-dialog',
  templateUrl: './link-created-dialog.component.html',
  styleUrls: ['./link-created-dialog.component.scss']
})
export class LinkCreatedDialogComponent implements OnInit {

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public inviteForm: FormGroup;
  fullAccess: boolean = true;
  rooms: RoomPermission[] = [];
  isAdmin: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<LinkCreatedDialogComponent>,
    private alertService: AlertService,
    private shareProjectService: ShareProjectService,
    @Inject(MAT_DIALOG_DATA)
    public data: InputSettings,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private projectsService: ProjectsService,
    private permissionsService: PermissionsService
  ) {
    this.inviteForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  ngOnInit() {
    this.getDataByActiveProject();
    if (this.data.email) {
      this.inviteForm.controls['email'].setValue(this.data.email);
      this.inviteForm.controls['email'].disable();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  get isEditMode(): boolean {
    return this.data.email !== undefined
  }

  copyLink() {
    navigator.clipboard.writeText(this.data.link).then().catch(e => console.log(e));
    this.alertService.openInfoModal('', this.translate.instant('share_project_copy_link_message'))
  }

  sendInvite() {
    // Send only if role is user (limited access)
    let permissions = {
      rooms: this.rooms.filter(el => el.checked).map(el => el.room.uuid)
    }

    this.shareProjectService.assignProject(
      this.data.projectId,
      this.inviteForm.value.email,
      this.fullAccess ? USER_ROLES.ADMIN : USER_ROLES.USER,
      !this.fullAccess ? permissions : undefined
    )
      .subscribe((res) => {
        this.alertService.openInfoModal('', this.translate.instant('share_project_success_message'))
        this.dialogRef.close();
        if (this.data.onShared) {
          this.data.onShared();
        }
      }, (err) => {
        this.alertService.openErrorModal('', err?.error?.error?.message || this.translate.instant('share_project_error_message'))
      })
  }

  selectRoom(room: RoomPermission) {
    room.checked = !room.checked;
  }

  toogleRooms() {
    this.fullAccess = !this.fullAccess;
  }

  private getDataByActiveProject() {
    this.projectsService.activeProject$.pipe(
      takeUntil(this.destroy$),
      switchMap(project => project ? this.getProjectPermissions(project) : of(null))
    ).subscribe(res => {
      if (!res.project) { return }

      // My permissions
      let myAccessToRooms = res.permissions[0]
      let userAccessToRooms = res.permissions[2]
      this.isAdmin = res.permissions[1]

      // User permissions
      this.fullAccess = userAccessToRooms?.role ? userAccessToRooms.role === USER_ROLES.ADMIN : this.isAdmin

      // Hide invisible rooms if role is user
      this.rooms = res.project.rooms
        .filter(el => this.isAdmin || myAccessToRooms.includes(el.uuid))
        .map(el => { return { 
          room: el, 
          checked: !this.fullAccess && userAccessToRooms?.rooms ? userAccessToRooms.rooms.includes(el.uuid) : true 
        } });
    });
  }

  private getProjectPermissions(project): Observable<any> {
    return combineLatest([
      this.permissionsService.getPermission(PERMISSIONS.ROOMS, project.project.id),
      this.permissionsService.getPermission(PERMISSIONS.IS_ADMIN, project.project.id),
      of(this.data.permissions)
    ])
    .pipe(map(el => { return { project: project, permissions: el } }))
  }
}
