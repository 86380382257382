<div class="link-created-dialog">
  <div class="link-created-dialog-header">
    {{'share_project_title' | translate}}
  </div>

  <div class="link-created-dialog-body">
    <h5 class="link-created-dialog-body-title">
      {{ 'share_project_message' | translate }}
    </h5>

    <div class="link-created-dialog-body__scrollable-content">
      <form class="invite" [formGroup]="inviteForm">
        <!-- Share button -->
        <input class="email" formControlName="email" type="text" placeholder="Email"/>
      </form>

      <!-- Advance -->
      <div class="checkbox-container">
        <div class="access-item">
          <input type="checkbox" [checked]="fullAccess" (change)="toogleRooms()" [disabled]="!isAdmin">
          <span>Full access: <b>{{fullAccess ? "Yes" : "No"}} </b></span>
        </div>
        
        <ul *ngIf="!fullAccess" class="link-created-dialog-body__room-list">
          <hr/>
          Access to rooms (uncheck to remove access):

          <li *ngFor="let room of rooms" [ngClass]="room.checked ? 'active' : ''" class="container-item room-item">
            <input type="checkbox" (change)="selectRoom(room)" [checked]="room.checked" [disabled]="!isAdmin">
            <span>{{room.room.name}}</span>
          </li>
        </ul>
      </div>
    </div>


    <hr>

    <div class="action-btns">
      <span></span>
      <!-- <span (click)="copyLink()" class="copy-button">{{'copy_link' | translate}}</span> -->

      <button mat-stroked-button color="primary" class="action-btn" (click)="dialogRef.close()">
        <span>{{'cancel' | translate}}</span>
      </button>
      <button mat-flat-button color="primary" [disabled]="inviteForm.invalid" (click)="sendInvite()">
        <span>{{ (isEditMode ? 'save' : 'share') | translate }}</span>
      </button>
    </div>
  </div>
</div>