<div class="confirm">
  <div class="confirm-card">
    <div class="pop-up-card-header center confirm-header">
      <span class="confirm-title">{{'Success' | translate}}</span>
    </div>
  </div>
  <div>
    <div class="success">
      <img src="assets/icons/vector.svg" alt="">
    </div>
    <div class="confirm-msg">
        <span class="msg">{{'The_file_will_be_sent_to_you_whenever_it’s_ready' | translate}}</span>
    </div>
  </div>
</div>
