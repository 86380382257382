import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {ProjectsService} from '../../../../../core/services/projects/projects.service';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subscription} from 'rxjs/internal/Subscription';


@Component({
  selector: 'app-active-project-switcher',
  templateUrl: './active-project-switcher.component.html',
  styleUrls: ['./active-project-switcher.component.scss'],
})
export class ActiveProjectSwitcherComponent implements OnInit, OnDestroy {
  private allProjects;
  projects;
  activeProject: any;
  projectFilter$ = new Subject<string>();

  public isOpen = false;
  private destroy$ = new Subject<boolean>();
  private subscriptionProjectList: Subscription;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private projectsService: ProjectsService
  ) {
    this.subscriptionProjectList = this.projectsService.getUpdateProjectList().subscribe(projectList => {
      this.activeProject = projectList;
      this.projects = projectList;
      this.allProjects = projectList;
    });

    // Project search
    this.projectFilter$.pipe(
      debounceTime(300), 
      distinctUntilChanged())
      .subscribe(search => {
        this.projects = (search?.trim()?.length || 0) > 0 ? 
          this.allProjects?.filter(el => el.name.toLowerCase().includes(search.toLowerCase())) :
          this.allProjects;
      });
  }

  ngOnInit() {
    this.projectsService.projects$.pipe(takeUntil(this.destroy$)).subscribe(projects => {
      this.projects = projects;
    });
    this.projectsService.activeProject$.pipe(takeUntil(this.destroy$)).subscribe(activeProject => {
      this.activeProject = activeProject;
    })
  }

  setActiveProject(projectId) {
    this.projectsService.setActiveProject(projectId).subscribe();
    this.isOpen = false;
  }

  toggleMenu() {
    this.isOpen = !this.isOpen;
    this.projectFilter$.next('');
  }

  onFilterChanged(text: string) {
    this.projectFilter$.next(text);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.subscriptionProjectList.unsubscribe();
  }
}
