<div class="device-settings-dialog">
  <div class="device-settings-dialog__head">
    <h4 class="device-settings-dialog__title">{{'device_settings_alert_network_title' | translate}}</h4>
    <p class="device-settings-dialog__caption">
      {{'device_settings_alert_network_message' | translate}}
  </div>
  <div class="device-settings-dialog__content">
    <ul class="device-settings-dialog__steps">
      <li class="device-settings-dialog__step">
        {{'device_settings_alert_network_switch_step' | translate}}
      </li>
      <li class="device-settings-dialog__step">
        <div class="d-flex flex-column">
          <span>{{'device_settings_alert_network_step' | translate}}</span>
          <span class="device-settings-dialog__bold">{{ this.projectSSID }}</span>
        </div>
      </li>
      <li class="device-settings-dialog__step">
        <div class="d-flex flex-column">
          <span>{{'device_settings_alert_password_step' | translate}}</span>
          <span class="device-settings-dialog__bold">{{ this.projectPassword }}
            <button class="device-settings-dialog__copy-btn" cdkCopyToClipboard="{{ this.projectPassword }}">
              <mat-icon svgIcon="copy-to-clipboard"></mat-icon>
            </button>
          </span>
        </div>
      </li>
      <li class="device-settings-dialog__step">
        <span>
          {{'device_settings_alert_please_wait_step' | translate}}
        </span>
      </li> 
    </ul>
  </div>
  <div class="device-settings-dialog__footer">
    <button type="button" mat-flat-button color="primary" class="device-settings-dialog__btn" (click)="continue()">{{'device_settings_alert_continue' | translate}}</button>
  </div>
</div>
