import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-data-range-calendar',
  templateUrl: './data-range-calendar.component.html',
  styleUrls: ['./data-range-calendar.component.scss']
})

export class DataRangeCalendarComponent implements OnInit, OnDestroy {
  @Input() startDate: number;
  @Input() endDate: number;
  @Output() dataRangeChanged: EventEmitter<{ start: number, end: number }> = new EventEmitter();
  dateRange = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });
  maxDate: Date = new Date();
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.dateRange.patchValue({start: new Date(this.startDate * 1000), end: new Date(this.endDate * 1000)});
    this.dateRange.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
    ).subscribe({
      next: dateRange => {
        if (dateRange.start > dateRange.end) {
          this.dateRange.controls.end.setErrors({matEndDateInvalid: true});
          return;
        }
        if (!dateRange.start || !dateRange.end) return;
        this.dataRangeChanged.emit({start: Date.parse(dateRange.start) / 1000, end: Date.parse(dateRange.end) / 1000})
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
