<div class="active-project-switcher notranslate">
  <span [hidden]="isOpen"
        class="active-project-switcher__title">{{ activeProject?.project?.alias || activeProject?.project?.name }}</span>

  <button type="button"
          class="active-project-switcher__btn-toggle"
          [ngClass]="isOpen ? 'active-project-switcher__btn-toggle_open' : 'active-project-switcher__btn-toggle_closed'">
    <input class="search-input" [ngModel]="projectFilter$ | async" (ngModelChange)='onFilterChanged($event)' [hidden]="!isOpen" type="text" placeholder="Search project"/>
    <mat-icon svgIcon="{{ isOpen ? 'arrow-up-icon' : 'arrow-down-icon' }}" (click)="toggleMenu()"></mat-icon>
  </button>

  <div class="active-project-switcher__dropdown" [hidden]="!isOpen">
    <button type="button" mat-menu-item *ngFor="let project of projects" (click)="setActiveProject(project.id)">
      <span class="active-project-switcher__dropdown__name">{{ project.name }}</span>
    </button>
  </div>
</div>
