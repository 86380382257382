<div class="navbar-container" [class.example-is-mobile]="mobileQuery.matches">
  <mat-sidenav-container class="navbar-sidenav-container">
    <!-- Side nav -->
    <mat-sidenav #snav [opened]="!mobileQuery.matches" [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" class="sidenav navigation">

      <app-navigation></app-navigation>

    </mat-sidenav>

    <!-- Main content -->
    <mat-sidenav-content class="sidenav-content">
      <div class="col-12 d-flex align-center">
        <button mat-icon-button (click)="snav.toggle()" class="toggle-nav-btn" [hidden]="!mobileQuery.matches">
          <mat-icon>menu</mat-icon>
        </button>
        <div class="app-header">
          <!--<app-header></app-header>-->
          <app-header-v2></app-header-v2>
        </div>
      </div>
      <router-outlet *ngIf="activeProject"></router-outlet>

      <div class="col-12 no-content" *ngIf="!activeProject">
        <h4 class="page-title">{{'no_projects' | translate}}</h4>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
