import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

export enum Icons {
  dashboard = 'dashboard-icon',
  users = 'users-icon',
  templates = 'templates-icon',
  projects = 'projects-icon',
  history = 'history-icon',
  home = 'home-icon',
  arrowDown = 'arrow-down-icon',
  arrowUp = 'arrow-up-icon',
  temperature = 'temperature-icon',
  humidity = 'humidity-icon',
  hpa = 'hPa-icon',
  notification = 'notification-icon',
  sensor = 'sensor',
  heating = 'heating',
  humid = 'humidity',
  airflow = 'airflow',
  co2 = 'co2',
  voc = 'voc',
  light = 'light',
  'noise-level' = 'noise-level',
  valve = 'valve',
  'water-temperature' = 'water-temperature',
  'heating-flow' = 'heating-flow',
  'heating-temp' = 'heating-temp',
  'comfort-template' = 'comfort-template',
  'hot-template' = 'hot-template',
  'night-template' = 'night-template',
  'vacation-template' = 'vacation-template',
  'configuration' = 'configuration',
  'custom-template' = 'custom-template',
  edit = 'edit',
  trash = 'trash',
  share = 'share',
  logOut = 'log-out',
  alarm = 'alarm',
  calendar = 'calendar',
  download = 'download',
  support = 'support',
  sensorMode = 'sensor-mode',
  settings = 'settings',
  copyToClipboard = 'copy-to-clipboard'
}

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(
      private matIconRegistry: MatIconRegistry,
      private domSanitizer: DomSanitizer
  ) {
  }

  public registerIcons(): void {
    this.loadIcons(Object.values(Icons), 'assets/icons');
  }

  private loadIcons(iconKeys: string[], iconUrl: string): void {
    iconKeys.forEach(key => {
      this.matIconRegistry.addSvgIcon(`${key}`, this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${key}.svg`));
    });
  }

}
